import axios from './axios';
import config from './../utils/config'

const BACKEND_URL = config.backendUrl;

export const getTotalRewards = () => axios.get(
  `${BACKEND_URL}/api/rewards`
).then(({data}) => data);

export const claimRewards = (amount) => axios.post(
  `${BACKEND_URL}/api/rewards/claim`,
  {amount}
).then(({data}) => data);
