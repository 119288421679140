import './App.scss';
import {handleLogin} from "./utils/web3-connector";
import {disconnectWeb3} from "./api/axios";
import {Rewards} from "./pages/rewards";
import {Header} from "./components/header";

function App() {
  return (
    <div className="app">
      <Header/>
      <Rewards/>
    </div>
  );
}

export default App;
