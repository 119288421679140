import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { completeLogin, logout, startLogin } from './auth';
import { disconnectWeb3 } from '../api/axios';
import { localStoragePrefix } from "./auth";
import config from './config';

const blockchainId = BigInt(parseInt(config.blockchainId, 10));
const blockchainRpc = config.rpc;

export const connectWeb3 = async () => {
  // eslint-disable-next-line no-use-before-define
  initWeb3Modal();
  return (new Web3(await window.web3Modal.connect()));
};

export const switchNetwork = async (web3) => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: web3.utils.toHex(blockchainId) }],
    });
  } catch (err) {
    console.error(err);
  }
};

// eslint-disable-next-line no-async-promise-executor
export const handleLogin = (walletId) => (new Promise(async (resolve, reject) => {
  let web3;
  try {
    web3 = await connectWeb3();
  } catch (error) {
    if (error !== 'Modal closed by user' && error?.message !== 'User closed modal') {
      console.error('Allow access to wallet account');
    }
    reject();
    return;
  }

  startLogin(walletId).then(
    (loginResponse) => {
      web3.currentProvider.request({
        method: 'personal_sign',
        params: [loginResponse.messageToSign, loginResponse.walletId, ''],
      }).then(
        (signature) => completeLogin(walletId, signature).then(() => resolve(walletId)),
      ).catch((err) => {
        disconnectWeb3();
        console.error('Sign message to login', err);
        reject();
      });
    },
  );
}));

const initWeb3Modal = () => {
  if (((typeof window) !== 'undefined') && !window.web3Modal) {
    window.web3Modal = new Web3Modal({
      theme: 'dark',
      cacheProvider: true,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              [blockchainId]: blockchainRpc,
            },
          },
        },
      },
    });
    window.addEventListener('load', () => {
      if (window.ethereum) {
        window.ethereum.enable();
        window.ethereum.on('accountsChanged', (walletIds) => {
          if (localStorage.getItem(localStoragePrefix + 'user')) {
            console.log('Account changed', walletIds);
            logout().then(handleLogin);
          }
        });
        window.ethereum.on('networkChanged', (newtworkId) => {
          if (localStorage.getItem(localStoragePrefix + 'user') && (newtworkId !== blockchainId)) {
            console.log('Network changed', newtworkId);
            logout();
          }
        });
      }
    });
  }
};

export const connectXceptContract = (web3) => (new web3.eth.Contract(
    config.abi,
    config.contract,
));

export const connectXceptRewardsContract = (web3) => (new web3.eth.Contract(
  config.rewardsAbi,
  config.rewardsContract
))

export default { connectWeb3 };
