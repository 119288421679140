import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import "./Rewards.scss";
import {ClaimButton} from "../../components/claim-button";
import {Input} from "../../components/input";
import {claimRewards, getTotalRewards} from "../../api/common";
import {getSession} from "../../utils/auth";
import {connectWeb3, connectXceptContract, connectXceptRewardsContract} from "../../utils/web3-connector";

export const Rewards = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(getSession() !== null);
  const [totalRewards, setTotalRewards] = useState(0);
  const [claimableRewards, setClaimableRewards] = useState(0);

  const updateRewards = async () => {
    const session = getSession();
    if (!session) {
      setIsLoggedIn(false);
      return;
    }
    setIsLoggedIn(true);
    const rewardsResponse = await getTotalRewards();
    setTotalRewards(rewardsResponse.availableXcept);
    setClaimableRewards(rewardsResponse.availableXcept);
  }

  useEffect(() => {
    updateRewards();
    window.addEventListener('storage', updateRewards);
    return () => {
      window.removeEventListener('storage', updateRewards);
    };
  }, [totalRewards, claimableRewards]);

  const [amountToClaim, setAmountToClaim] = useState(claimableRewards);
  const [canClaim, setCanClaim] = useState(claimableRewards > 0);
  const [isLoading, setIsLoading] = useState(false);

  const validate = (value) => {
    value = Number(value);
    if (isNaN(value)) {
      setCanClaim(false);
    }
    setCanClaim(value <= claimableRewards && value > 0);
  }

  const handleClaimAmountChange = ({target: {value: newValue}}) => {
    validate(newValue);
    setAmountToClaim(newValue);
  }

  const handleClaimClick = async () => {
    setIsLoading(true);
    try {
      const claim = await claimRewards(amountToClaim);
      const web3 = await connectWeb3();
      const contract = connectXceptRewardsContract(web3);
      const amount = web3.utils.toWei(claim['amount'], 'ether');
      const tx = await contract.methods.claim(claim.receiver, claim.token, amount, claim.claimId, claim.deadline, claim.signature);
      await tx.send({from: claim.sender});
      await updateRewards();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  const RewardsInfo = () => (<div className="ecosystem-rewards block">
    <h2>User Rewards</h2>
    <div className="ecosystem-rewards-container">
      <div className="ecosystem-rewards-gameception">
        <img src="/rewards-scheme.svg"/>
      </div>
      <div className="ecosystem-rewards-weighted">
        <div className="weighted-rewards-block">
          <div className="weighted-rewards-caption">
            <span>Weighted rewards</span>
          </div>
          <div className="weighted-rewards-item">
            <span className="weighted-rewards-item-icon">📶</span>
            <span className="weighted-rewards-item-caption">Volume based</span>
          </div>
          <div className="weighted-rewards-item">
            <span className="weighted-rewards-item-icon">🟡</span>
            <span className="weighted-rewards-item-caption">Token Holding</span>
          </div>
        </div>
        <p>Weighted rewards are subject to change as the eco-system expands and updates</p>
      </div>
    </div>
  </div>)

  if (!isLoggedIn) {
    return (
      <>
        <RewardsInfo/>
        <div className="block">
          <p>Please connect any wallet containing XCept tokens to view your rewards</p>
        </div>
      </>
    )
  }

  return (
    <>
      <RewardsInfo/>
      <div className="rewards block">
        <div className={"rewards-stats"}>
          <div className={"rewards-stats-block"}>
            <span className={"reward-value"}>{totalRewards.toFixed(2)} XCEPT</span>
            <span className={"reward-label"}>Total Rewards</span>
          </div>
          <div className={"rewards-stats-block"}>
            <span className={"reward-value"}>{claimableRewards.toFixed(2)} XCEPT</span>
            <span className={"reward-label"}>Rewards to Claim</span>
          </div>
        </div>
        <div className={"rewards-form-row"}>
          <Input value={amountToClaim} onChange={handleClaimAmountChange} placeholder={"enter XCEPT amount to claim"}/>
          <ClaimButton disabled={!canClaim && !isLoading} caption={'Claim Reward'} onClick={handleClaimClick}/>
        </div>
        {isLoading && <div className="loading-message"><span>Waiting transaction confirmation...</span>
          <div className="spinner"/>
        </div>}
      </div>
    </>
  )
}
