import './Header.scss';
import config from "../../utils/config";
import {getSession, logout} from "../../utils/auth";
import {connectWeb3, handleLogin, switchNetwork} from "../../utils/web3-connector";
import {disconnectWeb3} from "../../api/axios";
import {useEffect, useState} from "react";

const blockchainId = BigInt(parseInt(config.blockchainId, 10));

export const Header = () => {
  const [wallet, setWallet] = useState(getSession()?.user.walletId);

  useEffect(() => {
    const handleSessionStorageEvent = () => {
      setWallet(getSession()?.user.walletId);
    }
    window.addEventListener('storage', handleSessionStorageEvent);
    return () => {
      window.removeEventListener('storage', handleSessionStorageEvent);
    }
  }, [wallet]);


  const handleConnectWalletClick = async () => {
    if (wallet) {
      return;
    }

    let web3;
    try {
      web3 = await connectWeb3();
    } catch (error) {
      if (error !== 'Modal closed by user' && error?.message !== 'User closed modal') {
        console.error('Allow access to wallet account');
      }
      throw error;
    }

    const chainId = await web3.eth.getChainId();
    if (chainId !== blockchainId) {
      await switchNetwork(web3);
      await handleConnectWalletClick();
      return;
    }

    const walletId = (await web3.eth.getAccounts())[0];
    if (!walletId) {
      disconnectWeb3();
      console.error('Turn on Wallet Account');
      throw new Error("Wallet disabled");
    }

    try {
      await handleLogin(walletId);
    } catch (e) {
      console.error(e);
    }
  }

  const handleLogout = () => logout();

  return (
    <div className="header block">
      <div className="logo-container">
        <img className="logo" src="/xception-logo.png"/>
      </div>
      <h1></h1>
      <div>
        {wallet
          ? <div className={'wallet-panel'}>{wallet}<img className="logout-button clickable" src={"/close-icon.svg"} onClick={handleLogout}/></div>
          : <div className={'wallet-panel clickable'} onClick={handleConnectWalletClick}>Connect wallet</div>
        }
      </div>
    </div>
  )
}
