import {
  requestLoginComplete,
  requestLoginStart,
  requestLogout,
} from '../api/auth';
import { disconnectWeb3 } from '../api/axios';

export const localStoragePrefix = 'auth_'; // prefix is the solution for two different websites on the same domain

const KEY_SESSION = localStoragePrefix + 'session';
const KEY_USER = localStoragePrefix + 'user';
const KEY_TOKEN = localStoragePrefix + 'sessionToken';
const KEY_EXPIRE_AT = localStoragePrefix + 'sessionTokenExpirationDate';

const loginCallback = (session) => {
  localStorage.setItem(KEY_SESSION, JSON.stringify(session));
  localStorage.setItem(KEY_USER, JSON.stringify({ walletId: session.walletId }));
  localStorage.setItem(KEY_TOKEN, session.token);
  localStorage.setItem(KEY_EXPIRE_AT, session.expireAt);
  window.dispatchEvent(new Event('storage'));
};

export const startLogin = (walletId) => requestLoginStart(walletId);

export const completeLogin = (walletId, signature) => requestLoginComplete(walletId, signature).then(loginCallback);

export const processLogout = () => {
  localStorage.removeItem(KEY_SESSION);
  localStorage.removeItem(KEY_USER);
  localStorage.removeItem(KEY_TOKEN);
  localStorage.removeItem(KEY_EXPIRE_AT);
  window.dispatchEvent(new Event('storage'));
  disconnectWeb3();
};

export const getSession = () => {
  const session = localStorage.getItem(KEY_SESSION);
  if (!session) {
    return null;
  }
  const expireAt = new Date(localStorage.getItem(KEY_EXPIRE_AT));
  if (expireAt.getTime() < Date.now()) {
    processLogout();
    return null;
  }
  return {
    session,
    user: JSON.parse(localStorage.getItem(KEY_USER)),
    expireAt,
    token: localStorage.getItem(KEY_TOKEN)
  }
}


export const logout = () => requestLogout().then(processLogout);

export default { startLogin, completeLogin, logout };
