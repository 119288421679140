import PropTypes from "prop-types";
import React from "react";
import "./ClaimButton.scss";

export const ClaimButton = ({className = '', caption, onClick = () => {}, fullwidth = false, disabled = false}) => {

  return (
    <button className={`default-button ${className}${fullwidth ? 'fullwidth' : ''}`} onClick={onClick} disabled={disabled}>{caption}</button>
  )
}
