import axios from './axios';
import config from './../utils/config'

const BACKEND_URL = config.backendUrl;

export const requestLoginStart = (walletId) => axios.post(
    `${BACKEND_URL}/api/auth/login/start`,
    { walletId },
).then(({ data }) => data);

export const requestLoginComplete = (walletId, signature) => axios.post(
    `${BACKEND_URL}/api/auth/login/complete`,
    { walletId, signature },
).then(({ data }) => data);

export const requestLogout = () => axios.post(`${BACKEND_URL}/api/auth/logout`);

export const checkAuth = () => axios.post(`${BACKEND_URL}/api/auth/check`);

export default { requestLoginStart, requestLoginComplete, requestLogout, checkAuth };
